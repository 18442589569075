import { Circle } from 'components/Marker/Circle';
import { Event } from 'models/events';
import { getStatusColors } from 'utils/statusColor';

export function EventStatusBadge({ event }: { event: Event }) {
  return (
    <div className="flex gap-1 items-center">
      <Circle color={event.status === 'Pending' ? '#FE3D03' : '#44E568'} />
      <span className={`text-${getStatusColors(event.status)}`}>{event.status}</span>
    </div>
  );
}
