import React from 'react';

// Card component is a wrapper component for all the cards in the application
// it has a white background and a rounded border
// it takes in a children prop which is the content of the card
export default function Card({
  children,
  width,
  maxHeight = '',
}: {
  children: React.ReactNode;
  width: string;
  maxHeight?: string;
}) {
  return (
    <div
      className={`bg-white rounded-lg border-light border-primary-border h-full overflow-hidden ${maxHeight}`}
      style={{ width }}
    >
      {children}
    </div>
  );
}
