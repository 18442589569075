import { ReactComponent as IconNoData } from 'assets/icons/no-data.svg';

export function NoThreatFallback() {
  return (
    <div className="w-full py-12 flex flex-col justify-center items-center gap-6 bg-white rounded-lg">
      <IconNoData />
      <span className="text-xl">No data to show</span>
      <span className="text-sm text-center">
        No detections found under this threat or in the selected time range, <br /> so there’s no
        activity to display here. When data becomes available, you’ll see a <br />
        full overview of threat trends and security insights.
      </span>
    </div>
  );
}
