import { BEC, IMPERSONATION, INSIDER_THREAT, MALWARE } from 'constants/tabName';
import { ThreatCategory } from 'enums/threatCategory';

export const getThreatCategory = (category: string): ThreatCategory => {
  switch (category) {
    case BEC:
      return 'bec';
    case MALWARE:
      return 'malware';
    case INSIDER_THREAT:
      return 'insider_threat';
    case IMPERSONATION:
      return 'impersonation';
    default:
      return '';
  }
};

export const getLegendColor = (category: string): string => {
  const categoryToColor: Record<string, string> = {
    vip_impersonation: '#F7C6FF',
    vendor_impersonation: '#D173E0',
    regulator_impersonation: '#AC38BF',
    malware: '#FFB098',
    bec: '#85D8FF',
    financial_fraud: '#198ABF',
    attorney_fraud: '#62C6F5',
    invoice_fraud: '#BDEAFF',
    insider_threat: '#FFE03B',
  };

  return categoryToColor[category] || '#FFB6C1';
};
