import { createApi } from '@reduxjs/toolkit/query/react';
import {
  EventsResponse,
  GetEventsQueryParams,
  RemediatedEventsFilter,
  RemediatedEventsResults,
  Event,
} from 'models/events';
import { ALL_EVENTS_URL, QUARANTINE_EMAIL_API_URL, REMEDIATED_EVENTS } from 'constants/apiUrls';
import { getRandomNumber } from 'utils/randomNumber';
import { FolderType } from 'enums/eventStatusTypes';
import { getActionDetails } from 'utils/actionDescription';

import { baseQuery } from './baseQuery';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQuery,
  tagTypes: ['Events', 'SPECIFIC_EVENT'],
  endpoints: (builder) => ({
    getFlaggedEvents: builder.query<EventsResponse, { orgId: string } & GetEventsQueryParams>({
      query: ({
        orgId,
        offset = 0,
        limit = 10,
        from,
        to,
        sortBy,
        sortOrder = 'asc',
        vector,
        threat,
      }) => {
        const params = new URLSearchParams();

        params.append('offset', offset.toString());
        params.append('limit', limit.toString());

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (sortBy) params.append('sort_by', sortBy);
        if (sortOrder) params.append('sort_order', sortOrder);
        if (vector) params.append('vector', vector);
        if (threat) params.append('threats', threat);

        return `${ALL_EVENTS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['Events'],
      transformResponse: (response: EventsResponse) => {
        response.hits = response?.hits?.map((hit) => {
          return {
            ...hit,
            severity: getRandomNumber(80, 100),
            status: hit.status,
          };
        });
        return response;
      },
    }),
    remediateMessage: builder.mutation<
      undefined,
      { orgId: string; messageId: string; folder: FolderType }
    >({
      query: ({ orgId, messageId, folder }) => {
        const actionDetails = getActionDetails(folder);
        return {
          url: QUARANTINE_EMAIL_API_URL(orgId, messageId),
          method: 'POST',
          body: {
            action: folder,
            actionDetails,
          },
        };
      },
      invalidatesTags: ['Events'],
    }),
    undoRemediation: builder.mutation<
      undefined,
      { orgId: string; messageId: string; action: FolderType }
    >({
      query: ({ orgId, messageId, action }) => {
        const actionDetails = getActionDetails(action);
        return {
          url: `${QUARANTINE_EMAIL_API_URL(orgId, messageId)}/undo`,
          method: 'POST',
          body: {
            action,
            actionDetails,
          },
        };
      },
      invalidatesTags: ['Events'],
    }),
    getRemediatedEvents: builder.query<
      RemediatedEventsResults,
      { orgId: string } & RemediatedEventsFilter
    >({
      query: ({ orgId, offset = 0, limit = 10, from, to, sortBy, sortOrder }) => {
        const params = new URLSearchParams();

        params.append('offset', offset.toString());
        params.append('limit', limit.toString());

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (sortBy) params.append('sort_by', sortBy);
        if (sortOrder) params.append('sort_order', sortOrder);

        return `${REMEDIATED_EVENTS(orgId)}?${params.toString()}`;
      },
      providesTags: ['Events'],
    }),
    giveFeedback: builder.mutation<
      undefined,
      { orgId: string; messageId: string; feedback: boolean | null }
    >({
      query: ({ orgId, messageId, feedback }) => {
        return {
          url: `${ALL_EVENTS_URL(orgId)}/feedback`,
          body: {
            messageId,
            feedback,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['SPECIFIC_EVENT'],
    }),
    getFlaggedEvent: builder.query<Event, { organizationId: string; messageId: string }>({
      query: ({ organizationId, messageId }) => {
        return `${ALL_EVENTS_URL(organizationId)}/${messageId}`;
      },
      providesTags: ['SPECIFIC_EVENT'],
    }),
  }),
});

export const {
  useGetFlaggedEventsQuery,
  useRemediateMessageMutation,
  useGetRemediatedEventsQuery,
  useUndoRemediationMutation,
  useGiveFeedbackMutation,
  useGetFlaggedEventQuery,
} = eventsApi;
