import { useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Accordion from '@radix-ui/react-accordion';
import * as Select from '@radix-ui/react-select';

import { Event } from 'models/events';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconChevronDown } from 'assets/icons/button-with-circle.svg';
import { ReactComponent as IconAttachment } from 'assets/icons/attachment-icon.svg';
import { ReactComponent as IconLink } from 'assets/icons/link-icon.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info-icon.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconThumb } from 'assets/icons/thumb.svg';
import { ReactComponent as IconUnsafeAttachment } from 'assets/icons/unsafe-attachment.svg';
import { ReactComponent as IconUnSafeLink } from 'assets/icons/unsafe-link-icon.svg';

import { useGiveFeedbackMutation, useRemediateMessageMutation } from 'service/eventsApi';
import { useForceLogoutMutation } from 'service/users';
import { useOrgId } from 'hooks/useOrgId';

import Loader from 'components/Loader';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { WarningToast } from 'components/Toasts/WarningToast';
import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';
import { Divider } from 'components/Divider';
import Avatar from 'components/Avatar';

import { RemediationActions } from 'enums/remediationAction';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';

import { getInferenceDetailsByCode, getInferences, inferenceToProductMap } from './data';
import { EmailAnalysis } from './EmailAnalysis';
import { AnalysisBadge } from './AnalysisBadge';

interface EventOverviewProps {
  event: Event | undefined;
  open: boolean;
  onClose: () => void;
  generateActionDescription: (
    checkedStatus: Record<'logout' | 'quarantine', boolean>,
    actionResult: Record<'logout' | 'quarantine', boolean>
  ) => string;
  isLoading: boolean;
}

export function EventOverview({
  open,
  onClose,
  event,
  generateActionDescription,
  isLoading,
}: EventOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
  });

  const [loading, setLoading] = useState(false);

  const [remediateMsg] = useRemediateMessageMutation();

  const [forceLogoutUser] = useForceLogoutMutation();

  const [OrgId] = useOrgId();

  const [selectedRemediationAction, setSelectedRemediationAction] =
    useState<RemediationActions>('junk');

  const [feedbackForEvent] = useGiveFeedbackMutation();

  const [isUpvoted, setIsUpvoted] = useState<boolean | null>(null);
  useEffect(() => {
    if (event?.isUpvoted) {
      setIsUpvoted(event.isUpvoted);
    }
  }, [event]);

  if (!event || isLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 h-overview-modal  bg-white rounded-lg">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const handleToggle = (action: 'logout' | 'quarantine') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const handleRemediateCheck = () => {
    handleToggle('quarantine');
  };

  const handleSelect = (value: RemediationActions) => {
    setSelectedRemediationAction(value);
  };

  // handleRemediate completes 2 actions:
  // 1. Quarantine the mail
  // 2. Logout the user
  // depending on whether the user has checked the checkboxes or not
  const handleRemediate = async () => {
    if (!(checkedStateOfActions.logout || checkedStateOfActions.quarantine)) {
      showToast({
        component: <WarningToast message="Please select at least one action" />,
      });
      return;
    }

    setLoading(true);

    const actionResult = {
      logout: false,
      quarantine: false,
    };

    try {
      if (checkedStateOfActions.logout) {
        await forceLogoutUser({
          orgId: OrgId,
          employeeId: event.employeeId,
        }).unwrap();

        actionResult.logout = true;
      }

      if (checkedStateOfActions.quarantine) {
        await remediateMsg({
          orgId: OrgId,
          messageId: event.messageId,
          folder: selectedRemediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
        }).unwrap();

        actionResult.quarantine = true;
      }

      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  // getDefaultOpenAccordion returns the list of accordion values which have suspicious values
  const getDefaultOpenAccordion = (): string[] => {
    const accordions: string[] = [];

    if (getInferences('sender', event).length) {
      accordions.push('sender');
    }

    if (getInferences('recipients', event).length) {
      accordions.push('recipients');
    }

    if (event.attachments?.length) {
      event.attachments.forEach((attachment) => {
        if (attachment.suspicious) {
          accordions.push('attachments');
        }
      });
    }

    if (event.links?.length) {
      event.links.forEach((link) => {
        if (link.suspicious) {
          accordions.push('links');
        }
      });
    }

    return accordions;
  };

  // getFeedbackAction returns what action to perform on flagged message
  // if the message is already upvoted and user clicks on 'like' action which means
  // user is trying to revert it, hence we send null action. It's similar with dislike action.
  // in other cases we return action === 'like' (true in case of like and false in case of 'dislike')
  const getFeedbackAction = (action: 'like' | 'dislike') => {
    if (
      (action === 'like' && event.isUpvoted === true) ||
      (action === 'dislike' && event.isUpvoted === false)
    ) {
      return null;
    }

    return action === 'like';
  };

  const handleFeedback = async (action: 'like' | 'dislike') => {
    const feedbackAction = getFeedbackAction(action);
    setIsUpvoted(feedbackAction);

    try {
      await feedbackForEvent({
        orgId: OrgId,
        messageId: event.messageId,
        feedback: feedbackAction,
      }).unwrap();

      if (feedbackAction === null) {
        showToast({
          component: (
            <SuccessToast
              message={`${action === 'like' ? 'Upvote' : 'Downvote'} removed from flagged message`}
            />
          ),
        });
      } else {
        showToast({
          component: (
            <SuccessToast
              message={`Event successfully ${action === 'like' ? 'upvoted' : 'downvoted'}`}
            />
          ),
        });
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-lg flex justify-center">
            <div className="w-2/3 flex flex-col h-full overflow-y-auto no-scrollbar">
              <div className="p-8 flex justify-between items-center gap-16 border-b-light border-border-primary">
                {/* <span className="w-12 h-12 text-white bg-critical-red rounded-lg flex justify-center items-center text-2xl">
                    91
                  </span> */}
                <span>{event.subject}</span>
                <div className="px-1.5 py-1 bg-soft-sand rounded-full flex gap-1.5">
                  <button
                    type="button"
                    className={`rounded-full p-1.5 ${isUpvoted === true ? 'bg-success-alert' : 'bg-white'}`}
                    onClick={() => handleFeedback('like')}
                    aria-label="like"
                  >
                    <IconThumb
                      className={`w-2.5 h-2.5  ${isUpvoted === true ? 'fill-white' : 'fill-success-alert'}`}
                    />
                  </button>
                  <button
                    type="button"
                    className={`rounded-full p-1.5 ${isUpvoted === false ? 'bg-critical-red' : 'bg-white'}`}
                    onClick={() => handleFeedback('dislike')}
                    aria-label="dislike"
                  >
                    <IconThumb
                      className={`w-2.5 h-2.5 rotate-180 ${isUpvoted === false ? 'fill-white' : 'fill-critical-red'}`}
                    />
                  </button>
                </div>
              </div>
              <div className="flex-1">
                <div className="px-8 py-6">
                  <EmailAnalysis event={event} />
                  <Divider customCss="my-6 border-border-light border-border-primary" />
                  <Accordion.Root type="multiple" defaultValue={getDefaultOpenAccordion()}>
                    <Accordion.Item value="sender">
                      <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                        <span className="text-xs text-light-grey">Sender</span>
                        <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
                      </Accordion.Trigger>
                      <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                        <div className="flex flex-col gap-4 mt-4">
                          <div className="flex justify-between items-center mt-4 gap-4">
                            <div className="flex items-center gap-4">
                              <Avatar
                                initials={getInitials(event.sender.email, 2)}
                                bgColor="avatar-purple"
                                textColor="white"
                                customCssClass="w-6 h-6 p-1 text-xs"
                              />
                              <span className="text-sm text-black">{event.sender.email}</span>
                              {event.sender.regulator ? (
                                <div className="p-1 bg-soft-purple rounded-md text-dark-purple text-xs">
                                  Regulator
                                </div>
                              ) : null}
                              {event.sender.vendor ? (
                                <div className="p-1 bg-soft-purple rounded-md text-dark-purple text-xs">
                                  Vendor
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {getInferences('sender', event)?.length > 0 && (
                            <div className="flex flex-wrap gap-1">
                              {getInferences('sender', event)?.map((inference) => {
                                const inferenceDetails = getInferenceDetailsByCode(inference);
                                return (
                                  <AnalysisBadge
                                    key={inference}
                                    text={inferenceDetails.text}
                                    type={inferenceToProductMap[inference] || 'others'}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                    <Divider customCss="my-6 border-border-light border-border-primary" />
                    <Accordion.Item value="recipients">
                      <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                        <span className="text-xs text-light-grey">
                          Recipients{' '}
                          {`(${(event?.recipients?.length || 0) + (event?.cc?.length || 0) + (event?.bcc?.length || 0)})`}
                        </span>
                        <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
                      </Accordion.Trigger>
                      <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                        <div className="flex flex-col gap-4 mt-4">
                          {event?.recipients?.map((recipient) => (
                            <div className="flex justify-between items-center mt-4 gap-4">
                              <div className="flex items-center gap-4">
                                <Avatar
                                  initials={getInitials(recipient.email, 2)}
                                  bgColor="avatar-blue"
                                  textColor="white"
                                  customCssClass="w-6 h-6 p-1 text-xs"
                                />
                                <span className="text-sm text-black">{recipient.email}</span>
                                {recipient.vip ? (
                                  <div className="p-1 bg-soft-blue rounded-md text-avatar-blue text-xs">
                                    VIP
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                          {event?.cc?.map((recipient) => (
                            <div className="flex justify-between items-center mt-4 gap-4">
                              <div className="flex items-center gap-4">
                                <Avatar
                                  initials={getInitials(recipient.email, 2)}
                                  bgColor="avatar-blue"
                                  textColor="white"
                                  customCssClass="w-6 h-6 p-1 text-xs"
                                />
                                <span className="text-sm text-black">{recipient.email}</span>
                                <div className="p-1 bg-select-background rounded-md text-light-grey text-xs">
                                  CC
                                </div>
                              </div>
                            </div>
                          ))}
                          {event?.bcc?.map((recipient) => (
                            <div className="flex justify-between items-center mt-4 gap-4">
                              <div className="flex items-center gap-4">
                                <Avatar
                                  initials={getInitials(recipient.email, 2)}
                                  bgColor="avatar-blue"
                                  textColor="white"
                                  customCssClass="w-6 h-6 p-1 text-xs"
                                />
                                <span className="text-sm text-black">{recipient.email}</span>
                                <div className="p-1 bg-select-background rounded-md text-light-grey text-xs">
                                  BCC
                                </div>
                              </div>
                            </div>
                          ))}
                          {getInferences('recipients', event)?.length > 0 && (
                            <div className="flex flex-wrap gap-1">
                              {getInferences('recipients', event)?.map((inference) => {
                                const inferenceDetails = getInferenceDetailsByCode(inference);
                                return (
                                  <AnalysisBadge
                                    key={inference}
                                    text={inferenceDetails.text}
                                    type={inferenceToProductMap[inference] || 'others'}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                    <Divider customCss="my-6 border-border-light border-border-primary" />
                    <Accordion.Item value="attachments">
                      <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                        <span className="text-xs text-light-grey">
                          Attachments {`(${event.attachments?.length || '0'})`}
                        </span>
                        <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
                      </Accordion.Trigger>
                      <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                        <div className="flex flex-col gap-4 mt-4">
                          {event.attachments?.map((attachment) => (
                            <div className="flex justify-between items-center mt-4 gap-4">
                              <div className="flex items-center gap-4">
                                {attachment.suspicious ? (
                                  <IconUnsafeAttachment className="w-6 h-6" />
                                ) : (
                                  <IconAttachment className="w-6 h-6" />
                                )}
                                <span
                                  className={`text-sm ${attachment.suspicious ? 'text-red-600' : 'text-black'}`}
                                >
                                  {attachment.name}
                                </span>
                              </div>
                            </div>
                          ))}
                          {getInferences('attachments', event)?.length > 0 && (
                            <div className="flex flex-wrap gap-1">
                              {getInferences('attachments', event)?.map((inference) => {
                                const inferenceDetails = getInferenceDetailsByCode(inference);
                                return (
                                  <AnalysisBadge
                                    key={inference}
                                    text={inferenceDetails.text}
                                    type={inferenceToProductMap[inference] || 'others'}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                    <Divider customCss="my-6 border-border-light border-border-primary" />
                    <Accordion.Item value="links">
                      <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                        <span className="text-xs text-light-grey">
                          Links {`(${event.links?.length || '0'})`}
                        </span>
                        <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
                      </Accordion.Trigger>
                      <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                        <div className="flex flex-col gap-4 mt-4">
                          {event.links?.map((link) => (
                            <div className="flex justify-between items-center mt-4 gap-4">
                              <div className="flex items-center gap-4">
                                {link.suspicious ? (
                                  <IconUnSafeLink className="w-6 h-6" />
                                ) : (
                                  <IconLink className="w-6 h-6" />
                                )}
                                <span
                                  className={`text-sm ${link.suspicious ? 'text-red-600' : 'text-black'}`}
                                >{`${link.root}${link.path}`}</span>
                              </div>
                            </div>
                          ))}
                          {getInferences('links', event)?.length > 0 && (
                            <div className="flex flex-wrap gap-1">
                              {getInferences('links', event)?.map((inference) => {
                                const inferenceDetails = getInferenceDetailsByCode(inference);
                                return (
                                  <AnalysisBadge
                                    key={inference}
                                    text={inferenceDetails.text}
                                    type={inferenceToProductMap[inference] || 'others'}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion.Root>
                </div>
              </div>
            </div>
            <div className="w-1/3 border-l-light border-border-primary flex flex-col justify-between gap-8 relative">
              <button
                type="submit"
                className="absolute w-fit top-4 right-4 p-1 rounded-full border-light border-border-primary flex justify-center items-center hover:bg-black cursor-pointer"
                onClick={() => onClose()}
                aria-label="Close"
              >
                <IconCross className="w-2 h-2 hover:stroke-white" />
              </button>
              <div className="p-8">
                <div className="flex flex-col">
                  <span className="text-lg text-light-grey">Suggested Remediation Actions</span>
                  <Divider customCss="border-border-light border-border-primary mt-6 mb-4" />
                  <div className="w-full flex gap-4 items-center">
                    <Checkbox.Root
                      className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                      checked={checkedStateOfActions.logout}
                      onClick={() => handleToggle('logout')}
                    >
                      <Checkbox.Indicator>
                        <IconCheck />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <button
                      className="flex flex-col gap-1"
                      onClick={() => handleToggle('logout')}
                      type="button"
                    >
                      <span className="text-xs">Log Out Recepients & Reset Password</span>
                    </button>
                  </div>
                  <Divider customCss="border-border-light border-border-primary my-4" />
                  {event.status === 'Pending' ? (
                    <>
                      <div className="w-full flex gap-4 justify-between relative">
                        <div className="flex items-center gap-4">
                          <Checkbox.Root
                            className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                            checked={checkedStateOfActions.quarantine}
                            onClick={() => handleRemediateCheck()}
                          >
                            <Checkbox.Indicator>
                              <IconCheck />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <button
                            className="flex flex-col gap-1"
                            onClick={() => handleRemediateCheck()}
                            type="button"
                          >
                            <span className="text-xs">Push Mail to</span>
                          </button>
                        </div>
                        <Select.Root defaultValue="junk" onValueChange={handleSelect}>
                          <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                            <span className="text-black">
                              <Select.Value />
                            </span>
                            <Select.Icon>
                              <IconArrowDown className="w-4 h-4" />
                            </Select.Icon>
                          </Select.Trigger>
                          <Select.Portal>
                            <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                              <Select.Viewport>
                                <Select.Item
                                  value="quarantine"
                                  className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                >
                                  <Select.ItemText>Quarantine</Select.ItemText>
                                </Select.Item>
                                <Select.Item
                                  value="junk"
                                  className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                >
                                  <Select.ItemText>Junk</Select.ItemText>
                                </Select.Item>
                              </Select.Viewport>
                            </Select.Content>
                          </Select.Portal>
                        </Select.Root>
                      </div>
                      <Divider customCss="border-border-light border-border-primary my-4" />
                    </>
                  ) : null}
                </div>
              </div>
              <div className="px-8 py-6 border-t-light border-border-primary flex flex-col gap-8 items-center">
                <div className="flex flex-row gap-4 items-center">
                  <IconInfo className="w-6 h-6" />
                  <span className="text-xs text-light-grey">
                    Any email that will is moved to quarantine will stay there up to{' '}
                    <strong className="font-black"> 7 days </strong> as per your Retention policy
                  </span>
                </div>
                <button
                  className="w-full bg-success-alert rounded-full text-white p-4 text-center text-base"
                  type="button"
                  onClick={() => handleRemediate()}
                >
                  {loading ? <Loader color="white" /> : 'Trigger Actions'}
                </button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
