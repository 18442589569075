import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_THREAT_CATEGORY_STATS, GET_THREATS } from 'constants/apiUrls';
import { GetThreatsFilter, ThreatStatsResult } from 'models/threats';
import { Metric } from 'models/metric';

import { baseQuery } from './baseQuery';

export const threatsApi = createApi({
  reducerPath: 'threatsApi',
  baseQuery: baseQuery,
  tagTypes: ['THREATS'],
  endpoints: (builder) => ({
    getThreatStats: builder.query<ThreatStatsResult, GetThreatsFilter>({
      query: (filter) => `${GET_THREATS(filter.orgId)}?category=${filter.threatCategory}`,
    }),
    getThreatCategoryStats: builder.query<Metric[], { orgId: string }>({
      query: (filter) => GET_THREAT_CATEGORY_STATS(filter.orgId),
    }),
  }),
});

export const { useGetThreatStatsQuery, useGetThreatCategoryStatsQuery } = threatsApi;
